import Vue from "vue";

const url = "dashboard/day-hires";

export default {
  name: "day-hires",
  namespaced: true,

  state() {
    return {};
  },

  actions: {
    async fetch(_ctx, { page, perPage, query }) {
      return await Vue.axios.get(
        `/dashboard/day-hires?page=${page}&per_page=${perPage}&${query}`
      );
    },
    async create(_ctx, { payload }) {
      const { data } = await Vue.axios.post(url, payload);

      return data;
    },
    async update(_ctx, { payload, id }) {
      const { data } = await Vue.axios.put(
        `dashboard/day-hires/${id}`,
        payload
      );

      return data;
    },
    async delete(_ctx, id) {
      const { data } = await Vue.axios.delete(`${url}/${id}`);
      return data;
    }
  }
};
